<template>
	<div class="assessBox">
		<div class="app-container">
			<div class="titlebox">
				<el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>
				<div class="title">{{ userName }}-评估详情</div>
			</div>
			<div class="viewbox">
				<div class="level_box">
					<div class="level_box_levelbox">
						<span class="level_box_now"
							>评估职级：{{
								rankName == 0
									? '战略决策层'
									: rankName == 1
									? '战略执行层'
									: rankName == 2
									? '部门运营层'
									: rankName == 3
									? '业务执行层'
									: '非管理层'
							}}级</span
						>
						<span v-show="isAssess" class="level_box_old">原有职级：{{ originRankName || '暂无评' }}级</span>
					</div>
					<div class="level_box_btnbox" v-show="isAssess">
						<!-- <el-button size="small" class="level_box_btnbox_audit" type="primary" @click="handleGoAudit">评估审核 </el-button> -->
						<!-- <el-button
							size="small"
							class="level_box_btnbox_export"
							type="primary"
							v-show="!assessmentType && auditor"
							@click="handleGetOriginal"
							>原始评估</el-button
						>
						<el-button size="small" class="level_box_btnbox_export" type="primary" v-show="assessmentType" @click="handleGetNew"
							>最新评估</el-button
						> -->
						<el-button size="small" class="level_box_btnbox_export" type="primary" @click="handleExport" v-show="importBtnVisible"
							>导出</el-button
						>
					</div>
				</div>
				<!-- <p>现职级：{{Viewform.managerStation}}级</p> -->
				<!-- <div class="title">评估专家填写</div> -->
				<!-- <div>
          职位胜任力要求的符合度（职位:
          <el-radio-group v-model="rankName" :disabled="isAssess">
            <el-radio label="0">战略决策层</el-radio>
            <el-radio label="1">总监</el-radio>
            <el-radio label="2">经理</el-radio>
            <el-radio label="3">主管</el-radio>
            <el-radio label="4">员工</el-radio>
          </el-radio-group>）
        </div> -->
				<div class="title" style="padding: 14px 0; font-weight: 600" v-if="isPosition || isManage || isShowAll">一、现岗胜任度评估</div>
				<template v-if="isPosition || isShowAll">
					<div style="margin-top: 10px">
						1、责任结果评估（适用所有评估对象）
						<div class="ptitle">a、评估所在层级</div>
						<div class="viewbox_position">
							<el-radio-group v-model="rankName" :disabled="isAssess">
								<el-radio label="0">战略决策层</el-radio>
								<el-radio label="1">战略执行层</el-radio>
								<el-radio label="2">部门运营层</el-radio>
								<el-radio label="3">业务执行层</el-radio>
								<el-radio label="4">非管理层及其他</el-radio>
							</el-radio-group>
						</div>
					</div>
					<div>
						<span class="tw24">关键区分事实描述</span>
						<el-input
							type="textarea"
							:disabled="isAssess"
							:autosize="{ minRows: 3, maxRows: 4 }"
							placeholder="1-500字"
							v-model="rankNote"
						></el-input>
					</div>
				</template>
				<!-- <div>
          <el-radio-group v-model="rankLevel" class="radioArr" :disabled="isAssess">
            <el-radio label="1">完全胜任</el-radio>
            <el-radio label="2">基本胜任</el-radio>
            <el-radio label="3">暂不胜任</el-radio>
          </el-radio-group>
        </div> -->
				<template v-if="isManage || isShowAll">
					<div class="ptitle">{{ isManage ? 1 : 2 }}、管理胜任力评估（部门运行层及以上层级需要评估且需要填写评估依据）</div>
					<div v-for="(text, index) in textObj" :key="index">
						<div class="ptitle">{{ text.text }}</div>
						<div>
							<el-radio-group v-model="textObj[index].radio" class="radioArr" :disabled="isAssess">
								<el-radio label="0">0级</el-radio>
								<el-radio label="1">1级</el-radio>
								<el-radio label="2">2级</el-radio>
								<el-radio label="3">3级</el-radio>
							</el-radio-group>
						</div>
						<span class="tw24">评估依据</span>
						<el-input
							type="textarea"
							:disabled="isAssess"
							:autosize="{ minRows: 3, maxRows: 4 }"
							placeholder="1-500字"
							v-model="text.value"
						></el-input>
					</div>
				</template>
				<template v-if="isManage || isShowAll">
					<div v-show="rankName == 0">
						{{ isManage ? 2 : 3 }}.现岗胜任度评估结果（非管理层及其他无需评价）
						<div class="ptitle">a、职位胜任力要求的符合度</div>
						<!--  v-show="rankName==0" -->
						<el-radio-group v-model="rankLevel" class="radioArr" :disabled="isAssess">
							<el-radio label="1">完全胜任</el-radio>
							<el-radio label="2">基本胜任</el-radio>
							<el-radio label="3">暂不胜任</el-radio>
						</el-radio-group>
					</div>
					<span class="tw24" v-show="rankName == 0">现岗胜任度综合评估说明</span>
					<el-input
						v-show="rankName == 0"
						type="textarea"
						:disabled="isAssess"
						:autosize="{ minRows: 3, maxRows: 8 }"
						v-model="competencyNote"
					></el-input>
				</template>
			</div>
			<div class="qualityevaluation">
				<template v-if="isQuality || isShowAll">
					<div class="title">二、五项素质评估（适用所有评估对象，且经理及以上层级需要分项描述依据）</div>
					<div v-for="(quality, index2) in Quality" :key="index2">
						<div class="ptitle">{{ index2 + 1 }}、{{ quality.text }}</div>
						<div>
							<el-radio-group v-model="quality.radio" class="radioArr" :disabled="isAssess">
								<el-radio v-for="(radio, index1) in quality.radioArr" :label="String(index1)" :key="index1"
									><span style="color: #000; padding: 0 5px">{{ index1 }}级</span
									><span style="color: #a2a2a2">
										(<span style="color: #000; padding: 0 5px">{{ radio.title }}</span
										>{{ radio.content }})</span
									></el-radio
								>
							</el-radio-group>
						</div>
						<span class="tw24">评估依据</span>
						<el-input
							:disabled="isAssess"
							type="textarea"
							:autosize="{ minRows: 3, maxRows: 4 }"
							placeholder="1-500字"
							v-model="quality.value"
						></el-input>
					</div>
					<div class="ptitle">综合素质评价</div>
					<el-input type="textarea" :disabled="isAssess" :autosize="{ minRows: 3, maxRows: 8 }" v-model="synthesizeAppraise"></el-input>
					<div class="Talentstype">
						<strong style="font-size: 18px">人才类型</strong>
						<el-radio-group v-model="talentType" class="radioArr" :disabled="isAssess">
							<el-radio label="0">企业家型人才</el-radio>
							<el-radio label="1">开创型人才</el-radio>
							<el-radio label="2">守成型人才</el-radio>
							<el-radio label="3">执行型人才</el-radio>
							<el-radio label="4">非人才</el-radio>
						</el-radio-group>
					</div>
					<div class="Postproposal">
						<strong style="font-size: 18px">任职建议</strong>
						<el-radio-group v-model="appointment" class="radioArr" :disabled="isAssess">
							<el-radio label="0">保存现岗</el-radio>
							<el-radio label="1">提拔使用</el-radio>
							<el-radio label="2">调岗使用</el-radio>
							<el-radio label="3">降级使用</el-radio>
							<el-radio label="4">其他</el-radio>
						</el-radio-group>
					</div>
					<div class="Postproposal">
						<strong style="font: bold 14px/25px 'Microsoft YaHei'">任职建议说明</strong>
						<el-input
							style="margin-top: 20px"
							class="Postproposal_input"
							type="textarea"
							:autosize="{ minRows: 3, maxRows: 4 }"
							v-model="appointmentNote"
							placeholder="1-500字"
							:disabled="isAssess"
						>
						</el-input>
					</div>
				</template>
				<div class="evaluator">
					<div>
						<span class="evaluator_name">评估人：{{ evaluateUsername }}</span
						><span>评估时间：{{ evaluateTime }}</span>
					</div>
					<div>
						<span class="evaluator_name" v-show="auditor">审核人：{{ auditor }}</span
						><span v-show="auditTime">审核时间：{{ auditTime }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import * as api from '@/api/talentassessment';
export default {
	data() {
		return {
			importBtnVisible: true,
			assessmentType: 0,
			textObj: [
				{ text: 'a、战略能力', radio: '', value: '' },
				{ text: 'b、变革能力', radio: '', value: '' },
				{ text: 'c、高效体系构建能力', radio: '', value: '' },
				{ text: 'd、人才队伍建设能力', radio: '', value: '' }
			],
			Quality: [
				{
					text: '主动性',
					radio: '',
					radioArr: [
						{
							title: '不主动',
							content: '不会自觉地完成工作任务，需要他人的督促。不能提前计划或思考问题，直到问题发生后才能意识到事情的严重性。'
						},
						{
							title: '主动行动',
							content:
								'主动性一级的人能主动行动，自觉投入更多的努力去工作，不需要别人督促，只要分配给他的工作，他就会自觉地投入时间去做。'
						},
						{
							title: '主动思考 快速行动',
							content:
								'及时发现某种机会和问题并快速做出反应。主动性二级的人不光能快速自觉地工作，而且会主动进行思考，预判某一种情况，然后采取相应的行动。'
						},
						{
							title: '未雨绸缪 提前行动',
							content: '主动性三级的人不会等着问题发生，而是会未雨绸缪，提前行动，规避问题甚至创造出机会来。'
						}
					],
					value: ''
				},
				{
					text: '概念思维',
					radio: '',
					value: '',
					radioArr: [
						{
							title: '想不清看不明',
							content: '不能准确而周密地考虑事物发生的原因，或者不能根据已有的经验或知识对当前所面临的问题做出正确的判断。'
						},
						{
							title: '简单类比',
							content:
								'能根据以往的成功经验，通过有限的信息对事物进行全面的认识或判断，能够辨别当前情况同过去的经历之间的相同之处和不同之处，在新的条件下成功复制以往经验。'
						},
						{
							title: '触类旁通',
							content:
								'通过对事物发展规律的总结与洞察，提炼出一套底层的方法逻辑，能够透过复杂的信息对眼前的问题进行整体分析和处理，抓住关键问题。对于陌生的知识或领域，能够快速穿透，跨越认知壁垒。'
						},
						{
							title: '深入浅出',
							content:
								'能够洞察复杂事物的底层本质，并将复杂的情况或观点用简单、通俗的方式清晰有效地表达出来。能够用更加简洁的方法阐述繁复的知识和现象。'
						}
					]
				},
				{
					text: '影响力',
					radio: '',
					value: '',
					radioArr: [
						{
							title: '不能影响',
							content: '不能清楚地表达自己要讲的内容或不能说服别人听从自己的观点。'
						},
						{
							title: '直接说服',
							content: '通过向别人讲述理由、证据、事实、数据等方式，直接说服别人。或者给别人留下好的印象，以便获得别人的认可。'
						},
						{
							title: '设身处地',
							content: '能换位思考，站在他人的角度，用他人的话去解决他的问题，或同时采取多种方式影响他人。'
						},
						{
							title: '综合策略',
							content: '运用复杂的策略影响他人或通过微妙的幕后操作使别人接受自己的观点。'
						}
					]
				},
				{
					text: '成就导向',
					radio: '',
					value: '',
					radioArr: [
						{
							title: '安于现状',
							content: '安于现状，不追求个人技术或专业修养方面的进步。'
						},
						{
							title: '追求更好',
							content: '努力将工作做得更好，或达到某个优秀的标准。'
						},
						{
							title: '挑战目标',
							content: '为自己设立富有挑战性的目标，并为达到这些目标而付诸行动。'
						},
						{
							title: '敢冒经过评估的风险',
							content: '在仔细权衡代价和利益、利与弊的基础上作出某种决策，为了获得更大的成功，敢于冒险。'
						}
					]
				},
				{
					text: '坚韧性',
					radio: '',
					value: '',
					radioArr: [
						{
							title: '扛不住',
							content: '不能控制自己的情绪，经受不了批评、挫折和压力，稍微遇到点压力就选择放弃。'
						},
						{
							title: '压不垮',
							content:
								'在工作中能够保持良好的体能和稳定的情绪状态；面对挫折时能够克制自己的消极情绪（愤怒、焦急、失望等），努力坚持，不言放弃。'
						},
						{
							title: '干得成',
							content: '不仅能在艰苦的环境中顶住压力，重要的是一定能把事做成，克服困难后会带来好的结果。'
						},
						{
							title: '解除掉',
							content: '能通过建设性的方式消除他人的敌意或保证自己情绪的稳定，不受制于压力的影响，能把压力解除，顺利完成任务。'
						}
					]
				}
			],
			originRankName: '',
			rankName: 1,
			rankLevel: 1,
			rankNote: '',
			talentType: '',
			competencyNote: '',
			synthesizeAppraise: '',
			appointment: '',
			appointmentNote: '',
			isAssess: true,
			userName: '',
			evaluateUsername: '',
			evaluateTime: '',
			auditor: '',
			auditTime: '',
			currentItem: {}
		};
	},
	computed: {
		tabKey() {
			return this.$route.query.tabKey;
		},
		// 素质
		isQuality() {
			return this.tabKey === '2';
		},
		// 站位
		isPosition() {
			return this.tabKey === '3';
		},
		// 管理能力
		isManage() {
			return this.tabKey === '4';
		},
		// 展示详情
		isShowAll() {
			return this.tabKey === '0';
		}
	},
	methods: {
		handleExport() {
			const params = {
				id: this.$route.query.id
			};

			const url = `${document.location.origin}/v1/talent/exportUserReport?params=${encodeURIComponent(JSON.stringify(params))}`;

			window.open(url);
		},
		getInfo() {
			api.selectUserAssessReport({
				request: {
					id: this.$route.query.id,
					planId: this.$route.query.planId,
					type: '1'
				}
			}).then((res) => {
				// eslint-disable-next-line guard-for-in
				for (const key in res) {
					for (const key1 in this) {
						if (key === key1) {
							this[key1] = res[key];
						}
					}
					this.textObj[0].radio = res.strategy;
					this.textObj[0].value = res.strategyNote;
					this.textObj[1].radio = res.changeExpert;
					this.textObj[1].value = res.changeNote;
					this.textObj[2].radio = res.flowExpert;
					this.textObj[2].value = res.flowNote;
					this.textObj[3].radio = res.employeeAdvocate;
					this.textObj[3].value = res.advocateNote;
					this.Quality[0].radio = res.initiative;
					this.Quality[0].value = res.initiativeNote;
					this.Quality[1].radio = res.conceptThinking;
					this.Quality[1].value = res.conceptNote;
					this.Quality[2].radio = res.influence;
					this.Quality[2].value = res.influenceNote;
					this.Quality[3].radio = res.achievementGuide;
					this.Quality[3].value = res.guideNote;
					this.Quality[4].radio = res.hardiness;
					this.Quality[4].value = res.hardinessNote;
				}
			});
		},
		// 跳转到评估审核页面
		handleGoAudit() {
			this.$router.push({
				name: 'Audit',
				query: {
					id: this.$route.query.id,
					form: this.$route.query.form,
					status: '1'
				}
			});
		}
		// 获取最新评估按钮，需要记录最新
		// handleGetNew() {
		// 	api.selectUserAssessReport({ request: { id: this.$route.query.id, type: '1' } }).then((res) => {
		// 		// 重现渲染一次数据
		// 		for (const key in res) {
		// 			for (const key1 in this) {
		// 				if (key === key1) {
		// 					this[key1] = res[key];
		// 				}
		// 			}
		// 		}
		// 		this.textObj[0].radio = res.strategy;
		// 		this.textObj[0].value = res.strategyNote;
		// 		this.textObj[1].radio = res.changeExpert;
		// 		this.textObj[1].value = res.changeNote;
		// 		this.textObj[2].radio = res.flowExpert;
		// 		this.textObj[2].value = res.flowNote;
		// 		this.textObj[3].radio = res.employeeAdvocate;
		// 		this.textObj[3].value = res.advocateNote;
		// 		this.Quality[0].radio = res.initiative;
		// 		this.Quality[0].value = res.initiativeNote;
		// 		this.Quality[1].radio = res.conceptThinking;
		// 		this.Quality[1].value = res.conceptNote;
		// 		this.Quality[2].radio = res.influence;
		// 		this.Quality[2].value = res.influenceNote;
		// 		this.Quality[3].radio = res.achievementGuide;
		// 		this.Quality[3].value = res.guideNote;
		// 		this.Quality[4].radio = res.hardiness;
		// 		this.Quality[4].value = res.hardinessNote;
		// 		// 提示已渲染原始数据
		// 		this.$message({
		// 			message: '获取最新评估数据成功',
		// 			type: 'success'
		// 		});
		// 		this.assessmentType = 0;
		// 		this.importBtnVisible = true;
		// 	});
		// },
		// 获取原始评估数据,重新渲染页面
		// handleGetOriginal() {
		// 	api.selectUserAssessReport({ request: { id: this.$route.query.id, type: '0' } }).then((res) => {
		// 		// 重现渲染一次数据
		// 		for (const key in res) {
		// 			for (const key1 in this) {
		// 				if (key === key1) {
		// 					this[key1] = res[key];
		// 				}
		// 			}
		// 		}
		// 		this.textObj[0].radio = res.strategy;
		// 		this.textObj[0].value = res.strategyNote;
		// 		this.textObj[1].radio = res.changeExpert;
		// 		this.textObj[1].value = res.changeNote;
		// 		this.textObj[2].radio = res.flowExpert;
		// 		this.textObj[2].value = res.flowNote;
		// 		this.textObj[3].radio = res.employeeAdvocate;
		// 		this.textObj[3].value = res.advocateNote;
		// 		this.Quality[0].radio = res.initiative;
		// 		this.Quality[0].value = res.initiativeNote;
		// 		this.Quality[1].radio = res.conceptThinking;
		// 		this.Quality[1].value = res.conceptNote;
		// 		this.Quality[2].radio = res.influence;
		// 		this.Quality[2].value = res.influenceNote;
		// 		this.Quality[3].radio = res.achievementGuide;
		// 		this.Quality[3].value = res.guideNote;
		// 		this.Quality[4].radio = res.hardiness;
		// 		this.Quality[4].value = res.hardinessNote;
		// 		// 提示已渲染原始数据
		// 		this.$message({
		// 			message: '获取原始评估数据成功',
		// 			type: 'success'
		// 		});
		// 		this.assessmentType = 1;
		// 		this.importBtnVisible = false;
		// 	});
		// }
	},
	mounted() {
		this.getInfo();
	}
};
</script>

<style lang="scss">
.assessBox .app-container .qualityevaluation .Postproposal_input {
	margin-top: 20px;
}

.assessBox .app-container .qualityevaluation .evaluator div span {
	font-weight: bold;
}

// 修改左边原始评估样式
.container {
	background-color: #fff;

	.divider_line {
		width: 100%;
		height: 30px;
		background-color: #f6fbf7;
		margin-bottom: 20px;
	}

	.container_box {
		.el-radio__input.is-disabled + span.el-radio__label {
			color: #606266;
		}
	}
}
</style>
<style lang="scss" scoped>
.level_box {
	display: flex;

	// background-color: pink;
	.level_box_now {
		color: #409eff;
		font-weight: bold !important;
		font-size: 18px;
	}

	.level_box_old {
		color: #ccc;
		font-weight: bold !important;
		font-size: 18px;
		margin-left: 40px;
	}

	.level_box_btnbox {
		flex: 1;
		display: inline;
		text-align: right;
		padding-right: 30px;

		// background-color: blue;
		.level_box_btnbox_audit {
			width: 80px;
			// height: 40px;
		}

		.level_box_btnbox_export {
			width: 80px;
		}
	}

	.level_box_levelbox {
		flex: 1;
	}
}

.evaluator {
	text-align: left;
	font: bold 19px/28px 'Microsoft YaHei';
	padding: 20px 0px;
	padding-left: 54%;

	.evaluator_name {
		font-weight: bold;
		margin-right: 20px;
		min-width: 113px;
		display: inline-block;
	}
}
</style>

<style lang="scss" scoped>
.assessBox {
	.app-container {
		color: #484848;
		padding: 0;

		// background-color: #f6f7fb;
		.tw24 {
			display: block;
			margin: 5px 0;
			// position: relative;
			// top: -24px;
		}

		> .el-button {
			float: left;
			color: #484848;
			transform: translateY(-8px);
			font-size: 16px;
		}

		.titlebox {
			text-align: center;
			line-height: 17px;
			background: #fff;
			font: bold 18px/25px 'Microsoft YaHei';
			padding: 14px 40px;
			margin: 20px;

			> .el-button {
				float: left;
				color: #484848;
				transform: translateY(-8px);
				font-size: 16px;
			}
		}

		.viewbox {
			padding: 30px 0 0px 140px;
			margin: 0 20px;
			background: #fff;

			.el-radio {
				margin-right: 20px;
			}

			> p {
				color: #4a8df0;
				font-weight: bold;
				font-size: 18px;
			}

			> div {
				margin-bottom: 14px;
				font: bold 14px/25px 'Microsoft YaHei';

				span {
					font-weight: normal;
				}
			}

			> div:nth-child(9) {
				margin-bottom: 0;
			}

			.title {
				font: bold 18px/25px 'Microsoft YaHei';
				color: #484848;
			}

			.ptitle {
				font: bold 14px/25px 'Microsoft YaHei';
				margin-bottom: 10px;
			}

			.el-textarea {
				width: 80%;
				// margin-left: 10px;
				vertical-align: middle;

				.el-textarea__inner {
					background-color: #f9fbff;
				}
			}

			> .el-textarea {
				width: 80%;
				margin-left: 0;
			}

			.radioArr {
				margin-left: 30px;
				margin-bottom: 10px;

				.el-radio {
					margin-right: 40px;
				}
			}
		}

		.qualityevaluation {
			padding: 30px 0 10px 140px;
			background: #fff;
			margin: 0 20px 20px;

			div {
				font-weight: bold;

				span {
					font-weight: normal;
					font-size: 14px;
				}
			}

			.title {
				font: bold 18px/25px 'Microsoft YaHei';
				margin-bottom: 10px;
			}

			.ptitle {
				font: bold 14px/25px 'Microsoft YaHei';
				margin: 10px 0;
			}

			.el-textarea {
				width: 80%;
				margin: 5px 0px;
				vertical-align: middle;

				.el-textarea__inner {
					background-color: #f9fbff;
				}
			}

			> .el-textarea {
				width: 80%;
				margin-left: 0;
			}

			.radioArr {
				.el-radio {
					width: 100%;
					margin: 10px 0;
				}
			}

			.Talentstype {
				margin-top: 15px;

				.radioArr {
					width: 80%;
					margin-left: 10px;

					.el-radio {
						width: 10%;
						margin: 10px 5px;
					}
				}
			}

			.buttonBox {
				margin-top: 20px;
				padding: 0 25%;

				.el-button {
					width: 100px;
				}
			}
		}

		.Postproposal {
			margin-top: 15px;

			.el-textarea {
				margin-left: 0;
				display: block;
				width: 80%;

				.el-textarea__inner {
					background-color: #f9fbff;
				}
			}

			.radioArr {
				width: 80%;
				margin-left: 10px;

				.el-radio {
					width: 12%;
					margin: 10px 5px;
				}
			}
		}
	}
}
</style>

<style lang="scss">
.assessBox .app-container .viewbox .level_box_now {
	font-weight: bold;
}

.assessBox .app-container .viewbox .level_box_old {
	font-weight: bold;
}
</style>
